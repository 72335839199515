<template>
  <div class="users-connection-setting">
    <div class="header">
      <h3>Gestion des connexions des utilisateurs</h3>
    </div>
    <hr />
    <div class="header-user">
      <div class="group">
        <div class="search">
          <span class="searchIcon" title="search">
            <font-awesome-icon icon="search" />
          </span>
          <input
            class="input-search"
            type="text"
            v-model="searchFilter"
            placeholder="Rechercher un utilisateur "
          />
        </div>
        <b-button
          size="sm"
          class="ml-2"
          variant="outline-primary"
          @click="refresh"
          :disabled="getUserLoading"
        >
          <font-awesome-icon class="icon" icon="sync-alt" />
        </b-button>
      </div>
      <div
        v-if="!initLoading && getUserLoading"
        class="init-loading three-dots-loading"
      >
        Chargement en cours
      </div>
      <div class="last-updated">
        Dernière mise à jour :
        <span class="time">{{ getLastUpdatedTime }}</span>
      </div>
    </div>
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <div v-else class="content-tab-users-connection">
      <b-table
        small
        ref="connectionstable"
        class="table"
        :items="items"
        :fields="fields"
        sort-by.sync="user_full_name"
        :sort-desc.sync="sortDesc"
        sort-icon-left
      >
        <template v-slot:cell(user_full_name)="data">
          <strong>{{ data.item.user_full_name }}</strong>
          <div>{{ data.item.user_role }}</div>
        </template>
        <template v-slot:cell(device)="data">
          <strong>{{ data.item.device }}</strong>
          <div v-if="data.item.is_current_device" class="current-device-tag">
            Cet appareil
          </div>
        </template>
        <template v-slot:cell(location)="data">
          <strong>{{ data.item.location }}</strong>
          <div>{{ data.item.ip_address }}</div>
        </template>
        <template v-slot:cell(connection)="data">
          <font-awesome-icon
            v-if="data.item.is_logged"
            class="mr-1"
            :style="{ color: '#4CAF50' }"
            :icon="['fas', 'circle']"
          />
          <font-awesome-icon
            v-else
            class="mr-1"
            :style="{ color: '#4CAF50' }"
            :icon="['far', 'circle']"
          />
          <strong>{{ data.item.login_time | date }}</strong>
          <div>{{ data.item.login_time | timeFromNow }}</div>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="actions" v-if="data.item.is_logged">
            <b-button
              variant="outline-danger"
              size="sm"
              @click.prevent="openDisconnectModal(data.item)"
            >
              Déconnecter
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <!-- disconnect User Modal -->
    <b-modal
      ref="disconnectModal"
      title="Déconnecter cet utilisateur "
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de déconnecter l'utilisateur
        <strong class="name">
          {{ selectedConnection ? selectedConnection.user_full_name : '--' }}
        </strong>
        ?
      </p>
      <div class="form-actions">
        <b-button @click="hideModal" variant="outline-secondary">
          Annuler
        </b-button>
        <b-button variant="success" @click="handleDisconnect">Valider</b-button>
      </div>
      <div class="message">
        <div v-if="getUserLoading" class="loading three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getUsersConnectionsError" class="error">
          <ul v-if="Array.isArray(getUsersConnectionsError)">
            <li v-for="(e, index) in getUsersConnectionsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getUsersConnectionsError }}</span>
        </div>
      </div>
    </b-modal>
    <!-- END disconnect User Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  data() {
    return {
      selectedConnection: null,
      searchFilter: '',
      sortDesc: false,
      fields: [
        { key: 'user_full_name', label: 'Utilisateur', sortable: true },
        { key: 'device', label: 'Appareil' },
        { key: 'location', label: 'Localisation' },
        { key: 'connection', label: 'Connexion' },
        { key: 'actions', label: '' }
      ],
      initLoading: true
    }
  },
  methods: {
    ...mapActions(['fetchUsersConnections', 'disconnectUser']),
    openDisconnectModal(connection) {
      this.selectedConnection = connection
      this.$refs.disconnectModal.show()
    },
    hideModal() {
      this.$refs.disconnectModal.hide()
    },
    async handleDisconnect() {
      if (this.selectedConnection) {
        await this.disconnectUser(this.selectedConnection.id_connection)
        this.refresh()
        this.hideModal()
      }
    },
    itemsProviders() {
      let items = []
      items = this.getUsersConnections
      return items || []
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi')
      return regExp.test(this.removeDiacritics(haystack))
    },
    refresh() {
      this.fetchUsersConnections()
    }
  },
  computed: {
    ...mapGetters([
      'getUserLoading',
      'getUsersConnections',
      'getUsersConnectionsError',
      'getLastUpdatedTime'
    ]),
    items: function() {
      const search = this.searchFilter.trim()
      if (!search) {
        return this.getUsersConnections
      }
      return this.getUsersConnections.filter(c =>
        this.normalizedContains(
          c.user_full_name.toLowerCase(),
          search.toLowerCase()
        )
      )
    }
  },
  async mounted() {
    await this.fetchUsersConnections()
    this.initLoading = false
  },
  filters: {
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD HH:mm:ss').format(
        'DD MMM YYYY, HH:mm:ss'
      )
      return value
    },
    timeFromNow: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD HH:mm:ss').fromNow()
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.users-connection-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 11px;
    .group {
      display: flex;
      align-items: center;
    }
    .search {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .last-updated {
      font-size: 12px;
      color: #2dabe2;
      font-style: italic;
      .time {
        text-transform: capitalize;
      }
    }
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .input-search {
    border: 1px solid #dadada;
    outline: none;
    font-size: 16px;
    height: 40px;
    background: #fff;
    padding-left: 10px;
  }

  .searchIcon {
    padding: 10px;
    border: 1px solid #d6d8db;
    font-size: 16px;
    background: #d6d8da17;
  }

  .init-loading {
    padding: 5px 5px;
    width: 200px;
  }

  .content-tab-users-connection {
    padding-top: 4px;
    table {
      width: 100%;
      margin-bottom: 1rem;
      color: #212529;
      border: 0;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #b9babb;
      font-size: 12px;
      tr {
        td {
          .actions {
            margin-top: 4px;
            opacity: 0;
          }
        }
        &:hover {
          td {
            .actions {
              opacity: 1;
            }
          }
        }
      }

      .current-device-tag {
        font-weight: 700;
        text-align: center;
        width: 100px;
        background-color: #bfc0c1;
        text-transform: uppercase;
        border-radius: 10px;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .header-user {
      .search {
        input {
          width: 100%;
        }
      }
    }
    .content-tab-users-connection {
      overflow: auto;
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.users-connection-setting {
  .content-tab-users-connection {
    table {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
